.dialog-overlay {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, .8);
    z-index: 999999999;
}

.dialog-overlay a {
    display: block;
    position: absolute;
    top: 15px;
    right: 10px;
    color: #000;
    z-index: 2;
}

.dialog-overlay h3 {
    display: block;
    width: 100%;
    overflow: hidden;
    position: relative;
    top: 0;
    left: 0;
    color: #000;
  font-size: 24px;
    z-index: 2;
    background: white;
   padding:20px;
    text-align: center;
}



.dialog-overlay .iframe-holder {
    display: block;
    position: fixed;
    top: 25%;
    left: 50%;
    margin-top: 0;
    margin-left: -250px;
    height: 50%;
    min-height:500px;
    width: 500px;
    background: white;

}

@media @small-devices {
    .dialog-overlay .iframe-holder {
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        margin-top: 0;
        margin-left: 0;
        height: 100%;
        width: 100%;
        background: white;
        padding: 0;
    }
}

.dialog-overlay iframe {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    padding-top: 20px;
     padding-bottom: 0;
    background: white;
}
.dialog-footer{
    position:absolute;
    left:0;
    bottom:0;
  
    width:100%;
    background-color: #f5f5f5;
    padding:30px 30px;
 text-align: center;
 font-size:0;
}
.dialog-footer a{
    font-size:16px;
    display:inline-block;
    width:48%;
    padding:20px;
    background: #fff;
    color:#999999;
    position:relative;
    left:auto;
    top:auto;
    right:auto;
    text-align: center;
    text-decoration: none;
}
.dialog-footer a:hover{
    text-decoration: underline;

}
.dialog-footer a:first-child{
    margin-right: 2%;
}

.dialog-footer a.disabled{
       text-decoration: none;
       opacity:0.7;
       cursor: default;
}
.dialog-footer a:last-child{
    margin-left: 2%;
        background-color: @tint;
        color:white;
}
.dialog-footer a [class^='ic']{
background-image: url(../images/icons/spritemap-white.svg#arrow_right-view);
}
.dialog-footer a:hover [class^='ic']{
background-image: url(../images/icons/spritemap-white.svg#arrow_right-view);
}
.dialog-footer a:hover [class^='ic']{
background-image: url(../images/icons/spritemap-white.svg#arrow_right-view);
}
.dialog-text{
    text-align: center;
    padding:20px;
}
.dialog-icon{
    text-align: center;
    
       margin:20px 0; 
}
    .dialog-form{
           display:inline-block;
        width:60%;
        padding:20px; 
vertical-align: top;
    }
     .dialog-form label{
        display:block;
         padding:5px 0;  
    }
        .dialog-form input{
        display:inline-block;
         margin-right: 7px;
    margin-left: -20px;
    }
    .dialog-form-icons{
        display:inline-block;
        width:40%;
          padding:20px; 
          vertical-align: top;
          text-align: center;
    }

.dialog-icon .ic-tickets {
    background-image: url(../images/icons/spritemap-val.svg#ticketshop_big-view);
    width: 57px;
    height: 45px;
    background-size: 57px 45px;
 
}
.dialog-form-icons .ic-car {
    background-image: url(../images/icons/spritemap-val.svg#car_big-view);
    width: 57px;
    height: 57px;
    background-size: 57px 57px;
 
}
.dialog-form-icons .ic-motorcycle {
    background-image: url(../images/icons/spritemap-val.svg#motorcycle_big-view);
    width: 57px;
    height: 57px;
    background-size: 57px 57px;
 
}
